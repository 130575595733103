// src/components/MainLayout.js

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { RooftopContext } from "../context/RooftopContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faFileAlt,
  faDatabase,
  faChevronLeft,
  faChevronRight,
  faListOl,
  faTrophy,
  faUserShield,
  faMoon, // Icon for Dark Mode
  faSun, // Icon for Light Mode
  faBuilding, // Icon for Rooftop Selector
} from "@fortawesome/free-solid-svg-icons";
import "./MainLayout.css";
import logoLong from "./logo_long.png";
import logoTall from "./logo_tall.png"; // Import the tall logo for mobile

const MainLayout = ({ children, onLogout, user }) => {
  const { currentRooftop, setCurrentRooftop, setCurrentDatasetId } =
    useContext(RooftopContext);

  // State for sidebar visibility
  const [isSidebarVisible, setSidebarVisible] = useState(true);

  // State for expanded navigation group
  const [expandedGroup, setExpandedGroup] = useState(null);

  // State for account dropdown
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // State for selected section (for highlighting)
  const [selectedSection, setSelectedSection] = useState(null);

  // State for theme (dark/light mode)
  const [isDarkMode, setIsDarkMode] = useState(false);

  // State for loading and error messages
  // const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Add this with your other useState imports
  const [isRooftopPopoverOpen, setRooftopPopoverOpen] = useState(false);

  // Load theme from localStorage when the component mounts
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setIsDarkMode(savedTheme === "dark");
    }
  }, []);

  // Save theme to localStorage whenever the theme changes
  useEffect(() => {
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  // Handle window resize to adjust sidebar visibility
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSidebarVisible(false); // Hide sidebar on small screens by default
      } else {
        setSidebarVisible(true); // Show sidebar on large screens
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handler for link clicks in the sidebar
  const handleLinkClick = () => {
    // Collapse sidebar if the window width is below 768px (mobile)
    if (window.innerWidth < 768) {
      setSidebarVisible(false);
    }
    setDropdownOpen(false); // Close dropdown if open
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isRooftopPopoverOpen) {
        const popoverElement = document.querySelector(".rooftop-popover");
        const rooftopButtonElement = document.querySelector(".rooftop-button");

        if (
          popoverElement &&
          !popoverElement.contains(event.target) &&
          rooftopButtonElement &&
          !rooftopButtonElement.contains(event.target)
        ) {
          setRooftopPopoverOpen(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isRooftopPopoverOpen]);

  // Handler for Rooftop change
  const handleRooftopChange = async (newRooftop) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/select-rooftop`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({ rooftop: newRooftop }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCurrentRooftop(newRooftop);
        setCurrentDatasetId(data.datasetId);
      } else {
        console.error("Failed to update rooftop");
        const errorData = await response.json();
        setErrorMessage(errorData.error || "Failed to update rooftop");
      }
    } catch (error) {
      console.error("Error updating rooftop:", error);
      setErrorMessage("Error updating rooftop");
    }
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    const newSidebarState = !isSidebarVisible;
    setSidebarVisible(newSidebarState);
    if (!newSidebarState) {
      setExpandedGroup(null); // Collapse any expanded category when collapsing sidebar
    }
  };

  // Toggle expansion of a navigation group
  const handleGroupToggle = (group) => {
    if (!isSidebarVisible) {
      setSidebarVisible(true);
    }
    setExpandedGroup(expandedGroup === group ? null : group);
    setSelectedSection(group);
  };

  // Toggle account dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Close account dropdown
  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  // Toggle between dark and light mode
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`main-layout ${isDarkMode ? "dark-mode" : ""}`}>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {/* Header Section */}
      <header className="header">
        <div className="header-left">
          <Link to="/">
            {/* Two images, one for wide screens, one for mobile */}
            <img src={logoLong} alt="Logo" className="logo-long" />
            <img src={logoTall} alt="Logo" className="logo-tall" />
          </Link>
          <h1 className="title">{currentRooftop || "OptiSight Software"}</h1>
        </div>
        <div className="header-right">
          {/* Account Dropdown */}
          <div className="account-dropdown">
            <div
              onClick={toggleDropdown}
              className="account-link"
              aria-haspopup="true"
              aria-expanded={isDropdownOpen}
            >
              <span className="account-name">{user?.firstName}'s</span>
              <span className="account-text">Account</span>
            </div>
            {isDropdownOpen && (
              <div className="dropdown-content">
                <Link to="/account-details" onClick={closeDropdown}>
                  Account Details
                </Link>
                <button
                  onClick={() => {
                    onLogout();
                    closeDropdown();
                  }}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main Layout Content */}
      <div className="layout-content">
        {/* Sidebar Navigation */}
        <div className={`side-nav ${isSidebarVisible ? "" : "collapsed"}`}>
          {/* Sidebar Toggle Button */}
          <button
            className="toggle-button"
            onClick={toggleSidebar}
            aria-label="Toggle Sidebar"
          >
            <FontAwesomeIcon
              icon={isSidebarVisible ? faChevronLeft : faChevronRight}
            />
          </button>
          <ul>
            {/* Rooftop Menu */}
            <li
              onClick={() => setRooftopPopoverOpen(!isRooftopPopoverOpen)}
              className={`${isRooftopPopoverOpen ? "expanded" : ""} ${
                selectedSection === "Rooftop" ? "selected" : ""
              }`}
            >
              <div className="side-nav-item rooftop-button">
                <FontAwesomeIcon icon={faBuilding} className="icon" />
                {isSidebarVisible && "Rooftop"}
              </div>
              {isRooftopPopoverOpen && (
                <div
                  className="rooftop-popover"
                  style={{
                    width: isSidebarVisible ? "300px" : "300px", // Adjust width based on sidebar state
                  }}
                >
                  {user.dataset_mapping.map((entry, index) => (
                    <div
                      key={entry.rooftop}
                      className={`rooftop-option ${
                        currentRooftop === entry.rooftop ? "selected" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRooftopChange(entry.rooftop);
                      }}
                    >
                      <span className="rooftop-number">{index + 1}.</span>{" "}
                      {/* Store number */}
                      <span className="rooftop-name">{entry.rooftop}</span>
                      <hr className="rooftop-separator" />{" "}
                      {/* Separator line */}
                    </div>
                  ))}
                </div>
              )}
            </li>
            {/* Conditionally render Admin menu for users with 'admin' role */}
            {(user.userRole === "admin" ||
              user.permissions.includes("accessAdminSection")) && (
              <li
                onClick={() => handleGroupToggle("Admin")}
                className={`${expandedGroup === "Admin" ? "expanded" : ""} ${
                  selectedSection === "Admin" ? "selected" : ""
                }`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faUserShield} className="icon" />
                  {isSidebarVisible && "Admin"}
                </div>
                {expandedGroup === "Admin" && isSidebarVisible && (
                  <ul className="nested">
                    <li>
                      <Link
                        to="/admin/switchboard"
                        onClick={() => {
                          setSelectedSection("Admin");
                          handleLinkClick();
                        }}
                      >
                        Switchboard
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}

            {/* Dashboards Menu */}
            {user.permissions.includes("accessDashboards") && (
              <li
                onClick={() => handleGroupToggle("Dashboards")}
                className={`${
                  expandedGroup === "Dashboards" ? "expanded" : ""
                } ${selectedSection === "Dashboards" ? "selected" : ""}`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faChartPie} className="icon" />
                  {isSidebarVisible && "Dashboards"}
                </div>
                {expandedGroup === "Dashboards" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewSalesDashboard") && (
                      <li>
                        <Link
                          to="/dashboards/sales"
                          onClick={() => {
                            setSelectedSection("Dashboards");
                            handleLinkClick();
                          }}
                        >
                          Sales
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewCustomerDashboard") && (
                      <li>
                        <Link
                          to="/dashboards/customer"
                          onClick={() => {
                            setSelectedSection("Dashboards");
                            handleLinkClick();
                          }}
                        >
                          Customer
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewInventoryDashboard") && (
                      <li>
                        <Link
                          to="/dashboards/inventory"
                          onClick={() => {
                            setSelectedSection("Dashboards");
                            handleLinkClick();
                          }}
                        >
                          Inventory
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Forms Menu */}
            {user.permissions.includes("accessForms") && (
              <li
                onClick={() => handleGroupToggle("Forms")}
                className={`${expandedGroup === "Forms" ? "expanded" : ""} ${
                  selectedSection === "Forms" ? "selected" : ""
                }`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faFileAlt} className="icon" />
                  {isSidebarVisible && "Forms"}
                </div>
                {expandedGroup === "Forms" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("submitSalesForm") && (
                      <li>
                        <Link
                          to="/forms/sales"
                          onClick={() => {
                            setSelectedSection("Forms");
                            handleLinkClick();
                          }}
                        >
                          Sales
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("submitCustomerForm") && (
                      <li>
                        <Link
                          to="/forms/customer"
                          onClick={() => {
                            setSelectedSection("Forms");
                            handleLinkClick();
                          }}
                        >
                          Customer
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("submitInventoryForm") && (
                      <li>
                        <Link
                          to="/forms/inventory"
                          onClick={() => {
                            setSelectedSection("Forms");
                            handleLinkClick();
                          }}
                        >
                          Inventory
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Data Menu */}
            {user.permissions.includes("accessData") && (
              <li
                onClick={() => handleGroupToggle("Data")}
                className={`${expandedGroup === "Data" ? "expanded" : ""} ${
                  selectedSection === "Data" ? "selected" : ""
                }`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faDatabase} className="icon" />
                  {isSidebarVisible && "Data"}
                </div>
                {expandedGroup === "Data" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewInventoryData") && (
                      <li>
                        <Link
                          to="/data/inventory"
                          onClick={() => {
                            setSelectedSection("Data");
                            handleLinkClick();
                          }}
                        >
                          Inventory
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewPersonnelData") && (
                      <li>
                        <Link
                          to="/data/personnel"
                          onClick={() => {
                            setSelectedSection("Data");
                            handleLinkClick();
                          }}
                        >
                          Personnel
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Leaders Menu */}
            {user.permissions.includes("accessLeaders") && (
              <li
                onClick={() => handleGroupToggle("Leaders")}
                className={`${expandedGroup === "Leaders" ? "expanded" : ""} ${
                  selectedSection === "Leaders" ? "selected" : ""
                }`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faTrophy} className="icon" />
                  {isSidebarVisible && "Leaders"}
                </div>
                {expandedGroup === "Leaders" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewVariableLeaders") && (
                      <li>
                        <Link
                          to="/leaders/variable"
                          onClick={() => {
                            setSelectedSection("Leaders");
                            handleLinkClick();
                          }}
                        >
                          Variable
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewFixedLeaders") && (
                      <li>
                        <Link
                          to="/leaders/fixed"
                          onClick={() => {
                            setSelectedSection("Leaders");
                            handleLinkClick();
                          }}
                        >
                          Fixed
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}

            {/* Reports Menu */}
            {user.permissions.includes("accessReports") && (
              <li
                onClick={() => handleGroupToggle("Reports")}
                className={`${expandedGroup === "Reports" ? "expanded" : ""} ${
                  selectedSection === "Reports" ? "selected" : ""
                }`}
              >
                <div className="side-nav-item">
                  <FontAwesomeIcon icon={faListOl} className="icon" />
                  {isSidebarVisible && "Reports"}
                </div>
                {expandedGroup === "Reports" && isSidebarVisible && (
                  <ul className="nested">
                    {user.permissions.includes("viewSalesReport") && (
                      <li>
                        <Link
                          to="/reports/sales"
                          onClick={() => {
                            setSelectedSection("Reports");
                            handleLinkClick();
                          }}
                        >
                          Sales Report
                        </Link>
                      </li>
                    )}
                    {user.permissions.includes("viewTOReport") && (
                      <li>
                        <Link
                          to="/reports/to"
                          onClick={() => {
                            setSelectedSection("Reports");
                            handleLinkClick();
                          }}
                        >
                          TO Report
                        </Link>
                      </li>
                    )}
                    {/* Add more report links here if needed */}
                  </ul>
                )}
              </li>
            )}
          </ul>

          {/* Dark Mode Toggle Button at the Bottom */}
          <div className="side-nav-footer">
            <button
              onClick={toggleTheme}
              className="dark-mode-toggle-button"
              aria-label="Toggle Dark Mode"
            >
              <FontAwesomeIcon
                icon={isDarkMode ? faSun : faMoon}
                className="dark-mode-icon"
              />
              {isSidebarVisible && (
                <span className="dark-mode-text">
                  {isDarkMode ? "Light Mode" : "Dark Mode"}
                </span>
              )}
            </button>
          </div>
        </div>

        {/* Main Content Area */}
        <div
          className={`content ${isSidebarVisible ? "expanded" : "collapsed"}`}
        >
          {/* Display Current Rooftop as a Title */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
