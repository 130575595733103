import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './LoginPage.css';
import loginLogo from './login_logo.png'; 

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Important: include credentials with the request
        body: JSON.stringify({ username, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('accessToken', data.accessToken); // Store the token
        onLogin(data.accessToken, data.firstLogin); // Pass the firstLogin flag
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };  

  return (
    <div className="login-page">
      <div className="login-container">
        <img src={loginLogo} alt="Login-Logo" className="login-logo" />
        <h1 className="login-title">Login</h1>
        <form onSubmit={handleSubmit} className="login-form">
  <input
    type="text"
    placeholder="Username"
    value={username}
    onChange={(e) => setUsername(e.target.value)}
    required
  />
  <div className="password-field">
    <input
      type={showPassword ? "text" : "password"}
      placeholder="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />
    <span
      className="password-toggle-icon"
      onClick={() => setShowPassword(!showPassword)}
      role="button"
      aria-label="Toggle password visibility"
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </span>
  </div>
  {error && <p className="error-message">{error}</p>}
  <button type="submit">Login</button>
</form>
      </div>
    </div>
  );
};

export default LoginPage;
