// SwitchBoardPage.js

import React, { useEffect, useState, useCallback } from 'react';
import './SwitchBoardPage.css';
import { Tooltip as ReactTooltip } from 'react-tooltip'; // Ensure correct import
import Modal from 'react-modal';

Modal.setAppElement('#root');

const SwitchboardPage = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [permissionsList, setPermissionsList] = useState({
    access: [],
    submit: [],
    view: [],
    edit: [], // New group for "edit" permissions
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setUsers(data);
  
      const allPermissions = data.flatMap((user) => user.permissions || []);
      const uniquePermissions = Array.from(new Set(allPermissions));
  
      const accessPermissions = uniquePermissions.filter((perm) => perm.startsWith('access'));
      const submitPermissions = uniquePermissions.filter((perm) => perm.startsWith('submit'));
      const viewPermissions = uniquePermissions.filter((perm) => perm.startsWith('view'));
      const editPermissions = uniquePermissions.filter((perm) => perm.startsWith('edit')); // New filter
  
      setPermissionsList({
        access: accessPermissions,
        submit: submitPermissions,
        view: viewPermissions,
        edit: editPermissions, // Update state with "edit" permissions
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, [user.token]); // Include dependencies
  
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]); // Add fetchUsers to dependency array

  const handlePermissionChange = (userId, permission, isChecked) => {
    const updatedUsers = users.map((u) => {
      if (u.user_id === userId) {
        const updatedPermissions = isChecked
          ? [...u.permissions, permission]
          : u.permissions.filter((perm) => perm !== permission);
        return { ...u, permissions: updatedPermissions };
      }
      return u;
    });
    setUsers(updatedUsers);
  };

  const handleGroupChange = (userId, group, isChecked) => {
    const groupPermissions = permissionsList[group];
    const updatedUsers = users.map((u) => {
      if (u.user_id === userId) {
        const updatedPermissions = isChecked
          ? Array.from(new Set([...u.permissions, ...groupPermissions]))
          : u.permissions.filter((perm) => !groupPermissions.includes(perm));
        return { ...u, permissions: updatedPermissions };
      }
      return u;
    });
    setUsers(updatedUsers);
  };

  const isGroupChecked = (user, group) => {
    const groupPermissions = permissionsList[group];
    return groupPermissions.every((perm) => user.permissions.includes(perm));
  };

  const savePermissions = async (userId) => {
    const userToUpdate = users.find((u) => u.user_id === userId);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}/permissions`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify({ permissions: userToUpdate.permissions }),
      });
      if (!response.ok) {
        throw new Error('Failed to update permissions');
      }
      alert('Permissions updated successfully.');
    } catch (error) {
      console.error('Error updating permissions:', error);
      alert('Failed to update permissions.');
    }
  };

  // Define openModal and closeModal functions
  const openModal = (user) => {
    setSelectedUser(user);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalIsOpen(false);
  };

  return (
    <div className="switchboard-page">
      <h1>User Privileges Switchboard</h1>
      <table className="switchboard-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Role</th>
            <th>Access Permissions</th>
            <th>Submit Permissions</th>
            <th>View Permissions</th>
            <th>Edit Permissions</th> {/* New column header */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <React.Fragment key={u.user_id}>
              <tr
                onClick={() => openModal(u)}
                data-tooltip-id={`user-${u.user_id}`}
                data-tooltip-html={`
                  <div><strong>Role:</strong> ${u.user_role}</div>
                  <div><strong>Permissions Count:</strong> ${u.permissions.length}</div>
                `}
              >
                <td>{u.username}</td>
                <td>{u.user_role}</td>

                {/* Access Permissions Column */}
                <td>
                  <div className="permission-column">
                    <input
                      type="checkbox"
                      checked={isGroupChecked(u, 'access')}
                      onChange={(e) => handleGroupChange(u.user_id, 'access', e.target.checked)}
                    />
                    <strong>Select All Access</strong>
                    {permissionsList.access.map((permission) => (
                      <div key={`${u.user_id}-${permission}`} className="permission-checkbox">
                        <input
                          type="checkbox"
                          checked={u.permissions.includes(permission)}
                          onChange={(e) => handlePermissionChange(u.user_id, permission, e.target.checked)}
                        />
                        {permission}
                      </div>
                    ))}
                  </div>
                </td>

                {/* Submit Permissions Column */}
                <td>
                  <div className="permission-column">
                    <input
                      type="checkbox"
                      checked={isGroupChecked(u, 'submit')}
                      onChange={(e) => handleGroupChange(u.user_id, 'submit', e.target.checked)}
                    />
                    <strong>Select All Submit</strong>
                    {permissionsList.submit.map((permission) => (
                      <div key={`${u.user_id}-${permission}`} className="permission-checkbox">
                        <input
                          type="checkbox"
                          checked={u.permissions.includes(permission)}
                          onChange={(e) => handlePermissionChange(u.user_id, permission, e.target.checked)}
                        />
                        {permission}
                      </div>
                    ))}
                  </div>
                </td>

                {/* View Permissions Column */}
                <td>
                  <div className="permission-column">
                    <input
                      type="checkbox"
                      checked={isGroupChecked(u, 'view')}
                      onChange={(e) => handleGroupChange(u.user_id, 'view', e.target.checked)}
                    />
                    <strong>Select All View</strong>
                    {permissionsList.view.map((permission) => (
                      <div key={`${u.user_id}-${permission}`} className="permission-checkbox">
                        <input
                          type="checkbox"
                          checked={u.permissions.includes(permission)}
                          onChange={(e) => handlePermissionChange(u.user_id, permission, e.target.checked)}
                        />
                        {permission}
                      </div>
                    ))}
                  </div>
                </td>

                {/* Edit Permissions Column */}
                <td>
                  <div className="permission-column">
                    <input
                      type="checkbox"
                      checked={isGroupChecked(u, 'edit')}
                      onChange={(e) => handleGroupChange(u.user_id, 'edit', e.target.checked)}
                    />
                    <strong>Select All Edit</strong>
                    {permissionsList.edit.map((permission) => (
                      <div key={`${u.user_id}-${permission}`} className="permission-checkbox">
                        <input
                          type="checkbox"
                          checked={u.permissions.includes(permission)}
                          onChange={(e) => handlePermissionChange(u.user_id, permission, e.target.checked)}
                        />
                        {permission}
                      </div>
                    ))}
                  </div>
                </td>

                {/* Actions Column */}
                <td>
                  <button onClick={() => savePermissions(u.user_id)}>Save</button>
                </td>
              </tr>
              <ReactTooltip
                id={`user-${u.user_id}`}
                place="top"
                effect="solid"
                html={true}
              />
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Modal for Viewing User Details */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="modal"
        overlayClassName="overlay"
      >
        {selectedUser && (
          <div>
            <h2>{selectedUser.username}'s Details</h2>
            <p><strong>Role:</strong> {selectedUser.user_role}</p>
            <p><strong>Permissions:</strong> {selectedUser.permissions.join(', ')}</p>
            <button onClick={closeModal}>Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SwitchboardPage;
