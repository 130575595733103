// InventoryData.js
import React, { useEffect, useState, useRef, useContext } from 'react';
import './InventoryData.css'; // Custom CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faDownload, faSortUp, faSortDown, faUpload } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf'; // For generating PDF files
import 'jspdf-autotable'; // For generating tables in PDF
import { RooftopContext } from "../../context/RooftopContext";

const InventoryData = () => {
  const { currentRooftop } = useContext(RooftopContext);

  const [inventoryData, setInventoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [saleType, setSaleType] = useState('');
  const [stockNumber, setStockNumber] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showUpdateSection, setShowUpdateSection] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [currentUploadRecord, setCurrentUploadRecord] = useState(null);
  const [hoveredImageUrls, setHoveredImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [tooltipVisible, setTooltipVisible] = useState(false); // Tooltip visibility
  const timerRef = useRef(null); // Reference to the timer
  const fileInputRef = useRef(null);
  const recordsPerPage = 50;

  // Define the columns array
  const columns = [
    { header: 'Date Stocked', dataKey: 'stock_in_date' },
    { header: 'Stock Number', dataKey: 'stock_number' },
    { header: 'VIN', dataKey: 'vin_number' },
    { header: 'Sale Type', dataKey: 'sale_type' },
    { header: 'Marketed As', dataKey: 'marketed_status' },
    { header: 'Inventory Cost', dataKey: 'inventory_cost' },
    { header: 'Sale Price', dataKey: 'sale_price' },
    { header: 'Internet Price', dataKey: 'internet_price' },
    { header: 'Make', dataKey: 'make' },
    { header: 'Model', dataKey: 'model' },
    { header: 'Model Year', dataKey: 'model_year' },
    { header: 'Mileage', dataKey: 'mileage' },
    { header: 'Status', dataKey: 'status' },
    // { header: 'Last Updated', dataKey: 'submitted_at' },
  ];

  useEffect(() => {
    const fetchInventoryData = async () => {
      setIsLoading(true);
      try {
        const token = sessionStorage.getItem('accessToken');
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/inventory-data?rooftop=${currentRooftop}`;
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch inventory data");
        }

        const data = await response.json();
        setInventoryData(data);
        setFilteredData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInventoryData();
  }, [currentRooftop]);

  const handleFilter = () => {
    const filtered = inventoryData.filter(record => {
      const recordDate = new Date(record.stock_in_date?.value || record.stock_in_date);
      const isDateInRange = (!startDate || new Date(startDate) <= recordDate) && 
                            (!endDate || new Date(endDate) >= recordDate);
      const isSaleTypeMatching = !saleType || record.sale_type === saleType;
      const isStockNumberMatching = !stockNumber || record.stock_number.includes(stockNumber);
      
      return isDateInRange && isSaleTypeMatching && isStockNumberMatching;
    });
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleRecordSelect = (record) => {
    const recordWithValues = {};
    Object.keys(record).forEach(key => {
      if (key !== 'submitted_at') {
        recordWithValues[key] = record[key]?.value || record[key];
      }
    });

    setSelectedRecord(recordWithValues);
    setShowUpdateSection(true);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const numericFields = ['inventory_cost', 'sale_price', 'internet_price', 'model_year', 'mileage'];
    const updatedValue = numericFields.includes(name) ? (value === '' ? '' : Number(value)) : value;

    setSelectedRecord({
      ...selectedRecord,
      [name]: updatedValue,
    });
  };

  const handleUpdate = async () => {
    const confirmUpdate = window.confirm('Are you sure you want to update this record?');
    if (!confirmUpdate) return;

    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('accessToken');
      const { inventory_images, submitted_at, ...recordToUpdate } = selectedRecord; // Exclude inventory_images and submitted_at

      // Ensure required fields are NUMERIC
      const numericFields = ['inventory_cost', 'sale_price', 'internet_price'];
      numericFields.forEach(field => {
        if (recordToUpdate[field] !== undefined && recordToUpdate[field] !== null) {
          recordToUpdate[field] = parseFloat(recordToUpdate[field]).toFixed(2); // Ensure two decimal places
        }
      });      

      if (Object.keys(recordToUpdate).length === 0) {
        alert('No fields to update.');
        setIsLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/update-inventory-record?rooftop=${currentRooftop}`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updatedRecord: recordToUpdate }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update record");
      }

      alert('Record updated successfully!');
      setShowUpdateSection(false);

      // Update inventoryData and filteredData
      setInventoryData(prevData => prevData.map(record => {
        if (
          (record.stock_number?.value || record.stock_number) === (selectedRecord.stock_number?.value || selectedRecord.stock_number) &&
          (record.vin_number?.value || record.vin_number) === (selectedRecord.vin_number?.value || selectedRecord.vin_number)
        ) {
          return { ...record, ...recordToUpdate };
        }
        return record;
      }));

      setFilteredData(prevData => prevData.map(record => {
        if (
          (record.stock_number?.value || record.stock_number) === (selectedRecord.stock_number?.value || selectedRecord.stock_number) &&
          (record.vin_number?.value || record.vin_number) === (selectedRecord.vin_number?.value || selectedRecord.vin_number)
        ) {
          return { ...record, ...recordToUpdate };
        }
        return record;
      }));
    } catch (error) {
      alert('Error updating record: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    const newSortDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(newSortDirection);

    const sortedData = [...filteredData].sort((a, b) => {
      const aValue = a[column]?.value || a[column];
      const bValue = b[column]?.value || b[column];

      // Handle undefined or null values
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return newSortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      }

      const aStr = aValue.toString().toLowerCase();
      const bStr = bValue.toString().toLowerCase();

      if (aStr < bStr) return newSortDirection === 'asc' ? -1 : 1;
      if (aStr > bStr) return newSortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredData(sortedData);
  };

  const getSortIcon = (column) => {
    if (sortColumn === column) {
      return sortDirection === 'asc' ? faSortUp : faSortDown;
    }
    return null;
  };

  const renderSortIcon = (column) => {
    const icon = getSortIcon(column);
    return icon ? <FontAwesomeIcon icon={icon} /> : null;
  };

  const handleDownloadCSV = () => {
    const csvContent = generateCSV(filteredData);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'inventory_data.csv');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setShowDownloadOptions(false);
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF({ orientation: 'landscape' });

    // Define the table headers based on the columns array
    const tableColumns = columns.map(col => col.header);

    // Map through the filteredData and retrieve all the column values
    const tableRows = filteredData.map(record => columns.map(col => {
      const value = record[col.dataKey]?.value || record[col.dataKey] || '';
      if (['inventory_cost', 'sale_price', 'internet_price'].includes(col.dataKey)) {
        return formatCurrency(value);
      } else if (col.dataKey === 'submitted_at') {
        return formatTimestamp(value);
      }
      return value;
    }));

    // Create the PDF table using autoTable
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      theme: 'striped',
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: { fillColor: [41, 128, 185] },
      startY: 20,
      // Adjust column widths if necessary
    });

    // Save the PDF
    doc.save('inventory_data.pdf');
    setShowDownloadOptions(false);
  };

  const generateCSV = (data) => {
    if (data.length === 0) return '';

    const headers = columns.map(col => col.dataKey).join(',');

    const rows = data.map(row => 
      columns.map(col => {
        const value = row[col.dataKey]?.value || row[col.dataKey] || '';
        if (['inventory_cost', 'sale_price', 'internet_price'].includes(col.dataKey)) {
          return formatCurrency(value);
        } else if (col.dataKey === 'submitted_at') {
          return formatTimestamp(value);
        }
        return `"${value}"`;
      }).join(',')
    ).join('\n');

    return `${headers}\n${rows}`;
  };

  // const formatDate = (dateString) => {
  //   if (!dateString) return '';
  //   const options = { year: 'numeric', month: 'long', day: 'numeric' };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const options = { 
      year: 'numeric', month: 'long', day: 'numeric', 
      hour: '2-digit', minute: '2-digit', second: '2-digit' 
    };
    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return '';
    return new Intl.NumberFormat(undefined, { 
      style: 'currency', currency: 'USD', minimumFractionDigits: 2 
    }).format(value);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (error) return <p>Error: {error}</p>;

  const getStatusClass = (status) => {
    if (status === 'sold') {
      return 'status-sold';
    } else if (status === 'available') {
      return 'status-available';
    }
    return '';
  };

  // Handler for Upload Image button click
  const handleUploadButtonClick = (record) => {
    setCurrentUploadRecord(record);
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the hidden file input
    }
  };

  // Handler for file input change
  const handleFileChange = async (e) => {
    if (!currentUploadRecord) return;

    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    // Optional: Validate file types and sizes
    const invalidFiles = files.filter(file => !file.type.startsWith('image/') || file.size > 5 * 1024 * 1024);
    if (invalidFiles.length > 0) {
      alert('Some files are invalid. Please upload image files under 5MB.');
      return;
    }

    // Prepare FormData for multiple files
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('images', file); // 'images' is the field name for multiple files
    });
    formData.append('stock_number', currentUploadRecord.stock_number?.value || currentUploadRecord.stock_number);
    formData.append('vin_number', currentUploadRecord.vin_number?.value || currentUploadRecord.vin_number);

    try {
      const token = sessionStorage.getItem('accessToken');
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/upload-inventory-images`; // Updated endpoint for multiple images

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to upload images");
      }

      const data = await response.json(); // Assuming backend returns { imageUrls: ['...', '...'] }

      // Update the specific record in inventoryData with the new imageUrls
      setInventoryData(prevData => prevData.map(record => {
        if (
          (record.stock_number?.value || record.stock_number) === (currentUploadRecord.stock_number?.value || currentUploadRecord.stock_number) &&
          (record.vin_number?.value || record.vin_number) === (currentUploadRecord.vin_number?.value || currentUploadRecord.vin_number)
        ) {
          const existingImages = record.inventory_images || [];
          return {
            ...record,
            inventory_images: [...existingImages, ...data.imageUrls], // Append new image URLs
          };
        }
        return record;
      }));

      // Also update filteredData
      setFilteredData(prevData => prevData.map(record => {
        if (
          (record.stock_number?.value || record.stock_number) === (currentUploadRecord.stock_number?.value || currentUploadRecord.stock_number) &&
          (record.vin_number?.value || record.vin_number) === (currentUploadRecord.vin_number?.value || currentUploadRecord.vin_number)
        ) {
          const existingImages = record.inventory_images || [];
          return {
            ...record,
            inventory_images: [...existingImages, ...data.imageUrls], // Append new image URLs
          };
        }
        return record;
      }));

      alert('Images uploaded successfully!');
    } catch (error) {
      console.error('Error uploading images:', error);
      alert('Error uploading images: ' + error.message);
    } finally {
      setCurrentUploadRecord(null);
      // Reset the file input value
      e.target.value = null;
    }
  };

  // Handlers for hovering over table rows to display images with delay
  const handleRowMouseEnter = (record) => {
    // Start a 2-second timer to show the tooltip
    timerRef.current = setTimeout(() => {
      if (record.inventory_images && record.inventory_images.length > 0) {
        setHoveredImageUrls(record.inventory_images);
        setTooltipVisible(true);
      }
    }, 1000); // 2000 milliseconds = 2 seconds
  };

  const handleRowMouseLeave = () => {
    // Clear the timer and hide the tooltip
    clearTimeout(timerRef.current);
    setHoveredImageUrls([]);
    setTooltipVisible(false);
  };

  return (
    <div className="inventory-data-container">
      {/* Loading Indicator */}
      {isLoading && <div className="loading-overlay"><p>Loading...</p></div>}

      {/* Update Record Section */}
      {selectedRecord && (
        <div className={`update-section ${showUpdateSection ? 'expanded' : ''}`}>
          <button onClick={() => setShowUpdateSection(!showUpdateSection)}>
            {showUpdateSection ? 'Hide Update Section' : 'Show Update Section'}
          </button>
          {showUpdateSection && (
            <div className="update-form">
              <h3>Update Record</h3>
              {columns.map((col) => (
                <label key={col.dataKey}>
                  {col.header}:
                  <input 
                    type={['inventory_cost', 'sale_price', 'internet_price', 'model_year', 'mileage'].includes(col.dataKey) ? 'number' : 'text'}
                    name={col.dataKey}
                    value={selectedRecord[col.dataKey] || ''}
                    onChange={handleFieldChange}
                  />
                </label>
              ))}
              <button onClick={handleUpdate} className="submit-update-button">Submit Update</button>
            </div>
          )}
        </div>
      )}

      <h2>Inventory Data</h2>

      {/* Filter Section */}
      <div className="filter-section">
        <div className="filter-group">
          <label htmlFor="start-date">Start Date:</label>
          <input 
            id="start-date"
            type="date" 
            value={startDate} 
            onChange={(e) => setStartDate(e.target.value)} 
          />
        </div>
        <div className="filter-group">
          <label htmlFor="end-date">End Date:</label>
          <input 
            id="end-date"
            type="date" 
            value={endDate} 
            onChange={(e) => setEndDate(e.target.value)} 
          />
        </div>
        <div className="filter-group">
          <label htmlFor="sale-type">Sale Type:</label>
          <select 
            id="sale-type"
            value={saleType} 
            onChange={(e) => setSaleType(e.target.value)}
          >
            <option value="">All</option>
            <option value="New">New</option>
            <option value="Used">Used</option>
          </select>
        </div>
        <div className="filter-group">
          <label htmlFor="stock-number">Stock Number:</label>
          <input 
            id="stock-number"
            type="text" 
            value={stockNumber} 
            onChange={(e) => setStockNumber(e.target.value)} 
          />
        </div>

        {/* Apply Filters Button */}
        <button className="filter-section-button" onClick={handleFilter}>
          Apply Filters
        </button>

        {/* Download Dropdown */}
        <div className="filter-section-right download-dropdown-container">
          <button
            onClick={() => setShowDownloadOptions(!showDownloadOptions)}
            className="download-button"
            aria-label="Download Options"
          >
            <FontAwesomeIcon icon={faDownload} className="download-icon" title="Download" />
          </button>
          {showDownloadOptions && (
            <div className="download-dropdown">
              <button onClick={handleDownloadCSV}>Download CSV</button>
              <button onClick={handleDownloadPDF}>Download PDF</button>
            </div>
          )}
        </div>
      </div>

      {/* Hidden File Input for Image Upload */}
      <input 
        type="file" 
        accept="image/*" 
        multiple // Allow multiple files
        style={{ display: 'none' }} 
        ref={fileInputRef}
        onChange={handleFileChange}
      />

      {/* Inventory Table */}
      <div className="table-wrapper table-wrapper-top-scroll">
        <div className="table-scroll-wrapper">
          <table className="inventory-table">
            <thead>
              <tr>
                {columns.map(col => (
                  <th onClick={() => handleSort(col.dataKey)} key={col.dataKey}>
                    {col.header} {renderSortIcon(col.dataKey)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((record) => {
                const uniqueKey = `${record.stock_number}_${record.vin_number}`; // Removed submitted_at

                return (
                  <tr 
                    key={uniqueKey} 
                    onClick={() => handleRecordSelect(record)}
                    onMouseEnter={() => handleRowMouseEnter(record)}
                    onMouseLeave={handleRowMouseLeave}
                    className="inventory-row"
                  >
                    {columns.map(col => (
                      <td 
                        key={`${col.dataKey}_${uniqueKey}`} 
                        className={col.dataKey === 'status' ? getStatusClass(record[col.dataKey]?.value || record[col.dataKey]) : ''}
                      >
                        {['inventory_cost', 'sale_price', 'internet_price'].includes(col.dataKey) 
                          ? formatCurrency(record[col.dataKey]?.value || record[col.dataKey]) 
                          : (record[col.dataKey]?.value || record[col.dataKey])
                        }
                      </td>
                    ))}
                    <td>
                      <button 
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering row click
                          handleUploadButtonClick(record);
                        }}
                        className="upload-button"
                        title="Upload Images"
                        aria-label="Upload Images"
                      >
                        <FontAwesomeIcon icon={faUpload} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Hovered Images Display with Delay */}
      {tooltipVisible && hoveredImageUrls.length > 0 && (
        <div className="hovered-image-tooltip">
          {hoveredImageUrls.map((url, index) => (
            <img 
              key={index} 
              src={url} 
              alt={`Inventory ${index + 1}`} 
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/placeholder-image.png'; // Ensure this path is correct
              }}
            />
          ))}
        </div>
      )}

      {/* Pagination Controls */}
      <div className="pagination">
        {[...Array(Math.ceil(filteredData.length / recordsPerPage)).keys()].map((num) => (
          <button
            key={num + 1}
            onClick={() => paginate(num + 1)}
            className={currentPage === num + 1 ? 'active' : ''}
          >
            {num + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InventoryData;
