/* SalesReport.js */

import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'; // Import the plugin
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './SalesReport.css';

const getCurrentMonth = () => {
  const date = new Date();
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
};

const SalesReport = () => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [month, setMonth] = useState(getCurrentMonth());
  const [totals, setTotals] = useState({
    new: {
      frontGross: 0,
      backGross: 0,
      totalGross: 0,
      unitCount: 0,
    },
    used: {
      frontGross: 0,
      backGross: 0,
      totalGross: 0,
      unitCount: 0,
    }
  });
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const token = sessionStorage.getItem('accessToken');
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/sales-report?month=${month}`;

        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch sales data");
        }

        const data = await response.json();
        setSalesData(data);
        calculateTotals(data); // Calculate totals after fetching data
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sales report data:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchSalesData();
  }, [month]);

  const calculateTotals = (data) => {
    const newTotals = { frontGross: 0, backGross: 0, totalGross: 0, unitCount: 0 };
    const usedTotals = { frontGross: 0, backGross: 0, totalGross: 0, unitCount: 0 };

    data.forEach(row => {
      const frontGross = parseFloat(row.front_gross?.value || row.front_gross || 0);
      const backGross = parseFloat(row.back_gross?.value || row.back_gross || 0);
      const saleType = row.sale_type?.value || row.sale_type;

      if (saleType === 'New') {
        newTotals.frontGross += frontGross;
        newTotals.backGross += backGross;
        newTotals.totalGross += frontGross + backGross;
        newTotals.unitCount += 1;
      } else if (saleType === 'Used') {
        usedTotals.frontGross += frontGross;
        usedTotals.backGross += backGross;
        usedTotals.totalGross += frontGross + backGross;
        usedTotals.unitCount += 1;
      }
    });

    setTotals({
      new: newTotals,
      used: usedTotals,
    });
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const renderCell = (cellData) => {
    if (cellData && typeof cellData === 'object' && cellData.value !== undefined) {
      return cellData.value;
    }
    return cellData || '';
  };

  const formatCurrency = (value) => {
    if (isNaN(value)) return '$0.00';
    return value.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
  };

  // Download CSV Function
  const downloadCSV = () => {
    // Define the headers based on your table columns
    const headers = [
      'Deal Date',
      'Deal Number',
      'Customer First Name',
      'Customer Last Name',
      'VIN',
      'Stock Number',
      'Sale Type',
      'Incentive',
      'Front Gross',
      'Retail Price',
      // Add more headers if needed
    ];

    // Map the salesData to rows
    const rows = salesData.map(row => [
      `"${renderCell(row.deal_date)}"`,
      `"${renderCell(row.deal_number)}"`,
      `"${renderCell(row.customer_name_first)}"`,
      `"${renderCell(row.customer_name_last)}"`,
      `"${renderCell(row.vin_number)}"`,
      `"${renderCell(row.stock_number)}"`,
      `"${renderCell(row.sale_type)}"`,
      `"${formatCurrency(parseFloat(renderCell(row.incentive)))}"`,
      `"${formatCurrency(parseFloat(renderCell(row.front_gross)))}"`,
      `"${formatCurrency(parseFloat(renderCell(row.retail_price)))}"`,
      // Add more fields if needed
    ]);

    // Combine headers and rows
    const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n');

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `Sales_Report_${month}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Hide the dropdown after download
    setDropdownVisible(false);
  };

  // Download PDF Function using jspdf-autotable
  const downloadPDF = () => {
    const doc = new jsPDF({ orientation: 'landscape' });

    // Set the title
    doc.setFontSize(18);
    doc.text('Sales Report', 14, 22);

    // Define the table columns
    const tableColumn = [
      'Deal Date',
      'Deal Number',
      'Customer First Name',
      'Customer Last Name',
      'VIN',
      'Stock Number',
      'Sale Type',
      'Incentive',
      'Front Gross',
      'Retail Price',
    ];

    // Format the table rows
    const tableRows = salesData.map(row => [
      renderCell(row.deal_date),
      renderCell(row.deal_number),
      renderCell(row.customer_name_first),
      renderCell(row.customer_name_last),
      renderCell(row.vin_number),
      renderCell(row.stock_number),
      renderCell(row.sale_type),
      formatCurrency(parseFloat(renderCell(row.incentive))),
      formatCurrency(parseFloat(renderCell(row.front_gross))),
      formatCurrency(parseFloat(renderCell(row.retail_price))),
    ]);

    // Add the table using autoTable
    autoTable(doc, {
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      theme: 'striped', // You can choose 'striped', 'grid', 'plain'
      headStyles: {
        fillColor: [0, 51, 102], // Dark blue background for headers
        textColor: [255, 255, 255], // White text
        fontStyle: 'bold',
      },
      styles: {
        fontSize: 10,
        cellPadding: 3,
        halign: 'left',
        valign: 'middle',
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray for alternate rows
      },
      margin: { top: 30 },
      columnStyles: {
        0: { cellWidth: 20 }, // Deal Date
        1: { cellWidth: 25 }, // Deal Number
        2: { cellWidth: 30 }, // Customer First Name
        3: { cellWidth: 30 }, // Customer Last Name
        4: { cellWidth: 25 }, // VIN
        5: { cellWidth: 25 }, // Stock Number
        6: { cellWidth: 20 }, // Sale Type
        7: { cellWidth: 20 }, // Incentive
        8: { cellWidth: 20 }, // Front Gross
        9: { cellWidth: 25 }, // Retail Price
        // Add more column widths if needed
      },
      didDrawPage: function (data) {
        // Footer
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 10);
      },
    });

    // Save the PDF
    doc.save(`Sales_Report_${month}.pdf`);

    // Hide the dropdown after download
    setDropdownVisible(false);
  };

  // Toggle Dropdown Function
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="sales-report-container">
      <h2>Sales Report</h2>

      <label>
        Select Month:
        <input className="filter-calendar" type="month" value={month} onChange={handleMonthChange} />
      </label>

      <div className="totals-container">
        <div className="totals-section new-vehicle">
          <h3>New Vehicle Totals</h3>
          <p>Total Front Gross: {formatCurrency(totals.new.frontGross)}</p>
          <p>Total Back Gross: {formatCurrency(totals.new.backGross)}</p>
          <p>Total Gross: {formatCurrency(totals.new.totalGross)}</p>
          <p>Units Sold: {totals.new.unitCount}</p>
        </div>

        <div className="totals-section used-vehicle">
          <h3>Used Vehicle Totals</h3>
          <p>Total Front Gross: {formatCurrency(totals.used.frontGross)}</p>
          <p>Total Back Gross: {formatCurrency(totals.used.backGross)}</p>
          <p>Total Gross: {formatCurrency(totals.used.totalGross)}</p>
          <p>Units Sold: {totals.used.unitCount}</p>
        </div>
      </div>

      {/* Download Section */}
      <div className="export-section">
        <button
          className="download-button"
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={dropdownVisible}
          aria-label="Download Sales Report"
        >
          <FontAwesomeIcon icon={faDownload} /> Download
        </button>
        {dropdownVisible && (
          <div className="dropdown-menu" role="menu">
            <button onClick={downloadCSV} className="dropdown-item" role="menuitem">CSV</button>
            <button onClick={downloadPDF} className="dropdown-item" role="menuitem">PDF</button>
          </div>
        )}
      </div>

      <div className="table-wrapper">
        <table className="sales-report-table">
          <thead>
            <tr>
              <th>Deal Date</th>
              <th>Deal Number</th>
              <th>Customer First Name</th>
              <th>Customer Last Name</th>
              <th>VIN</th>
              <th>Stock Number</th>
              <th>Sale Type</th>
              <th>Incentive</th>
              <th>Front Gross</th>
              <th>Retail Price</th>
              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {salesData.map((row, index) => (
              <tr key={index}>
                <td>{renderCell(row.deal_date)}</td>
                <td>{renderCell(row.deal_number)}</td>
                <td>{renderCell(row.customer_name_first)}</td>
                <td>{renderCell(row.customer_name_last)}</td>
                <td>{renderCell(row.vin_number)}</td>
                <td>{renderCell(row.stock_number)}</td>
                <td>{renderCell(row.sale_type)}</td>
                <td>{formatCurrency(parseFloat(renderCell(row.incentive)))}</td>
                <td>{formatCurrency(parseFloat(renderCell(row.front_gross)))}</td>
                <td>{formatCurrency(parseFloat(renderCell(row.retail_price)))}</td>
                {/* Render more fields as needed */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesReport;

