// SalesForm.js

import React, { useState, useEffect } from 'react';
import './SalesForm.css';

const SalesForm = () => {
  // Helper function to format Date object to YYYY-MM-DD
  const getLocalDateFromDateObj = (dateObj) => {
    const yyyy = dateObj.getFullYear();
    const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
    const dd = String(dateObj.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  // Function to get local date in YYYY-MM-DD format
  const getLocalDate = () => {
    const today = new Date();
    return getLocalDateFromDateObj(today);
  };

  const [formData, setFormData] = useState({
    deal_date: getLocalDate(), // Use local date
    deal_number: '', // Will be set after fetching max deal number
    customer_name_first: '',
    customer_name_last: '',
    vin_number: '',
    stock_number: '',
    sale_type: '',
    incentive: '',
    front_gross: '',
    retail_price: '',
    test_drive: false,
    test_drive_count: '',
    make: '',
    model: '',
    sold_model_year: '',
    trade_make: '',
    trade_model: '',
    trade_model_year: '',
    trade_acv: '',
    trade_allowance: '',
    salesperson_1: '',
    salesperson_2: '',
    sales_manager: '',
    fi_producer: '',
    title: '', // Will be a dropdown
    payoff: '',
    down_payment: '',
    term_in: '',
    term_out: '',
    rate_in: '', // Changed to string to handle input with '%'
    rate_out: '', // Changed to string to handle input with '%'
    pay_in: '',
    pay_out: '',
    reserve: '',
    product_1: '',
    product_2: '',
    product_3: '',
    product_4: '',
    product_5: '',
    payment_type: '', // Will be a dropdown
    lender: '',
    back_gross: '',
    rdr_date: '',
  });

  const [searchValue, setSearchValue] = useState('');
  const [searchField, setSearchField] = useState('stock_number');
  const [showMetadata, setShowMetadata] = useState(true);
  const [showFrontSale, setShowFrontSale] = useState(true);
  const [showBackSale, setShowBackSale] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [salespeople, setSalespeople] = useState([]);
  const [fiProducers, setFiProducers] = useState([]);
  const [salesManagers, setSalesManagers] = useState([]);
  const [availableInventory, setAvailableInventory] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState('');

  // Fetch available inventory on mount
  useEffect(() => {
    const fetchAvailableInventory = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/available-inventory`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAvailableInventory(data);
        } else {
          console.error('Failed to fetch available inventory.');
        }
      } catch (error) {
        console.error('Error fetching available inventory:', error);
      }
    };

    fetchAvailableInventory();
  }, []);

  // Fetch maximum deal number on mount
  useEffect(() => {
    const fetchMaxDealNumber = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/max-deal-number`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const maxDealNumber = data.maxDealNumber || 0;
          console.log('Fetched maxDealNumber:', maxDealNumber);
          setFormData(prevState => ({
            ...prevState,
            deal_number: maxDealNumber + 1,
          }));
        } else {
          console.error('Failed to fetch max deal number:', response.statusText);
          // Optionally, set deal_number to a default value or handle the error
        }
      } catch (error) {
        console.error('Error fetching max deal number:', error);
      }
    };

    fetchMaxDealNumber();
  }, []);

  // Handle inventory selection
  const handleInventorySelect = (e) => {
    const selectedVin = e.target.value;
    const selectedItem = availableInventory.find(item => item.vin_number === selectedVin);
    
    if (selectedItem) {
      setFormData(prevState => ({
        ...prevState,
        sale_type: selectedItem.sale_type || '',
        stock_number: selectedItem.stock_number || '',
        vin_number: selectedItem.vin_number || '',
        make: selectedItem.make || '',
        model: selectedItem.model || '',
        sold_model_year: selectedItem.model_year || '',
      }));
    }
    
    setSelectedInventory(selectedVin);
  };

  // Fetch personnel by role on mount
  useEffect(() => {
    const fetchPersonnelByRole = async (role, setStateFunction) => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/personnel-by-role?role=${role}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setStateFunction(data);
        } else {
          console.error(`Failed to fetch ${role} data:`, response.statusText);
        }
      } catch (error) {
        console.error(`Error fetching ${role} data:`, error);
      }
    };

    fetchPersonnelByRole('salesperson', setSalespeople);
    fetchPersonnelByRole('fi producer', setFiProducers);
    fetchPersonnelByRole('sales manager', setSalesManagers);
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Special handling for rate_in and rate_out to strip '%' and convert to decimal
    if (name === 'rate_in' || name === 'rate_out') {
      // Remove any non-numeric characters (like '%')
      const numericValue = value.replace(/[^0-9.]/g, '');

      // Optional: Validate the input (e.g., ensure it's between 0 and 100)
      if (numericValue === '' || (/^\d+(\.\d{0,2})?$/.test(numericValue) && parseFloat(numericValue) <= 100)) {
        setFormData(prevState => ({
          ...prevState,
          [name]: numericValue,
        }));
      }
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  // Calculate Back Gross whenever Reserve or Products change
  useEffect(() => {
    const calculateBackGross = () => {
      const reserve = parseFloat(formData.reserve) || 0;
      const products = [
        parseFloat(formData.product_1) || 0,
        parseFloat(formData.product_2) || 0,
        parseFloat(formData.product_3) || 0,
        parseFloat(formData.product_4) || 0,
        parseFloat(formData.product_5) || 0,
      ];
      const backGross = reserve + products.reduce((acc, curr) => acc + curr, 0);
      setFormData(prevState => ({
        ...prevState,
        back_gross: backGross.toFixed(2), // Format to two decimal places
      }));
    };

    calculateBackGross();
  }, [formData.reserve, formData.product_1, formData.product_2, formData.product_3, formData.product_4, formData.product_5]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No token found');
      return;
    }

    // Convert rate_in and rate_out from percentage to decimal
    const rateInDecimal = parseFloat(formData.rate_in) / 100;
    const rateOutDecimal = parseFloat(formData.rate_out) / 100;

    const formatDate = (dateStr) => {
      const dateObj = new Date(dateStr);
      return isNaN(dateObj.getTime()) ? null : getLocalDateFromDateObj(dateObj);
    };

    const formattedFormData = {
      ...formData,
      rate_in: isNaN(rateInDecimal) ? null : rateInDecimal,
      rate_out: isNaN(rateOutDecimal) ? null : rateOutDecimal,
      deal_date: formData.deal_date ? formatDate(formData.deal_date) : null,
      rdr_date: formData.rdr_date ? formatDate(formData.rdr_date) : null,
      submitted_at: new Date().toISOString(),
    };

    console.log('Submitting Form Data:', formattedFormData);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/submit-sales`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ formData: formattedFormData }),
      });

      if (response.ok) {
        console.log('Sales data sent successfully.');
        alert('Sales data submitted successfully.');
        clearForm();
      } else {
        console.error('Failed to send sales data:', response.statusText);
        alert('Failed to submit sales data. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    }
  };

  // Handle search functionality
  const handleSearch = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/fetch-latest-sales-record?${searchField}=${searchValue}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data && data.length > 0) {
          const latestRecord = data[0];

          const isValidDate = (date) => {
            const parsedDate = new Date(date);
            return !isNaN(parsedDate.getTime());
          };

          const formattedDealDate = isValidDate(latestRecord.deal_date) 
            ? getLocalDateFromDateObj(new Date(latestRecord.deal_date)) 
            : '';

          const formattedRdrDate = isValidDate(latestRecord.rdr_date) 
            ? getLocalDateFromDateObj(new Date(latestRecord.rdr_date)) 
            : '';
            
          setFormData(prevState => ({
            ...prevState,
            deal_date: formattedDealDate,
            deal_number: latestRecord.deal_number || '', // Consider auto-increment here if needed
            customer_name_first: latestRecord.customer_name_first || '',
            customer_name_last: latestRecord.customer_name_last || '',
            vin_number: latestRecord.vin_number || '',
            stock_number: latestRecord.stock_number || '',
            sale_type: latestRecord.sale_type || '',
            incentive: latestRecord.incentive || '',
            front_gross: latestRecord.front_gross || '',
            retail_price: latestRecord.retail_price || '',
            test_drive: latestRecord.test_drive || false,
            test_drive_count: latestRecord.test_drive_count || '',
            make: latestRecord.make || '',
            model: latestRecord.model || '',
            sold_model_year: latestRecord.sold_model_year || '',
            trade_make: latestRecord.trade_make || '',
            trade_model: latestRecord.trade_model || '',
            trade_model_year: latestRecord.trade_model_year || '',
            trade_acv: latestRecord.trade_acv || '',
            trade_allowance: latestRecord.trade_allowance || '',
            salesperson_1: latestRecord.salesperson_1 || '',
            salesperson_2: latestRecord.salesperson_2 || '',
            sales_manager: latestRecord.sales_manager || '',
            fi_producer: latestRecord.fi_producer || '',
            title: latestRecord.title || '',
            payoff: latestRecord.payoff || '',
            down_payment: latestRecord.down_payment || '',
            term_in: latestRecord.term_in || '',
            term_out: latestRecord.term_out || '',
            rate_in: latestRecord.rate_in !== null ? (latestRecord.rate_in * 100).toFixed(2) : '',
            rate_out: latestRecord.rate_out !== null ? (latestRecord.rate_out * 100).toFixed(2) : '',
            pay_in: latestRecord.pay_in || '',
            pay_out: latestRecord.pay_out || '',
            reserve: latestRecord.reserve || '',
            product_1: latestRecord.product_1 || '',
            product_2: latestRecord.product_2 || '',
            product_3: latestRecord.product_3 || '',
            product_4: latestRecord.product_4 || '',
            product_5: latestRecord.product_5 || '',
            payment_type: latestRecord.payment_type || '',
            lender: latestRecord.lender || '',
            back_gross: latestRecord.back_gross || '',
            rdr_date: formattedRdrDate,
          }));
        } else {
          alert('No records found');
        }
      } else {
        console.error('Failed to fetch sales record:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Clear form fields and reset deal number
  const clearForm = () => {
    const fetchNewDealNumber = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/max-deal-number`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const maxDealNumber = data.maxDealNumber || 0;
          console.log('Fetched new maxDealNumber:', maxDealNumber);
          setFormData({
            deal_date: getLocalDate(),
            deal_number: maxDealNumber + 1,
            customer_name_first: '',
            customer_name_last: '',
            vin_number: '',
            stock_number: '',
            sale_type: '',
            incentive: '',
            front_gross: '',
            retail_price: '',
            test_drive: false,
            test_drive_count: '',
            make: '',
            model: '',
            sold_model_year: '',
            trade_make: '',
            trade_model: '',
            trade_model_year: '',
            trade_acv: '',
            trade_allowance: '',
            salesperson_1: '',
            salesperson_2: '',
            sales_manager: '',
            fi_producer: '',
            title: '',
            payoff: '',
            down_payment: '',
            term_in: '',
            term_out: '',
            rate_in: '',
            rate_out: '',
            pay_in: '',
            pay_out: '',
            reserve: '',
            product_1: '',
            product_2: '',
            product_3: '',
            product_4: '',
            product_5: '',
            payment_type: '',
            lender: '',
            back_gross: '',
            rdr_date: '',
          });
        } else {
          console.error('Failed to fetch max deal number for resetting form:', response.statusText);
          // Optionally, handle the error by notifying the user
        }
      } catch (error) {
        console.error('Error fetching max deal number during form reset:', error);
      }
    };

    fetchNewDealNumber();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="sales-form">
        <h2>Sales Form</h2>

        {/* Toggle Search Section */}
        <button type="button" onClick={() => setShowSearch(!showSearch)}>
          {showSearch ? 'Hide Search' : 'Show Search'}
        </button>

        {/* Search Section */}
        {showSearch && (
          <div className="search-section">
            <label>
              Search By:
              <select value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                <option value="stock_number">Stock Number</option>
                <option value="deal_number">Deal Number</option>
                <option value="vin_number">VIN Number</option>
              </select>
            </label>
            <input
              type="text"
              placeholder={`Enter ${searchField}`}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="button-container">
              <button type="button" onClick={handleSearch}>Search</button>
              <div className="button-divider"></div>
              <button type="button" onClick={clearForm}>Clear Form</button>
            </div>
          </div>
        )}

        {/* Inventory Selection */}
        <div className="inventory-selection">
          <label>
            Select Inventory:
            <select value={selectedInventory} onChange={handleInventorySelect}>
              <option value="">Select an Inventory Item</option>
              {availableInventory.map((item) => (
                <option key={item.vin_number} value={item.vin_number}>
                  {item.make} {item.model} ({item.model_year}) - {item.stock_number}
                </option>
              ))}
            </select>
          </label>
        </div>

        {/* Metadata Section */}
        <section className="form-section">
          <h3 className="section-header" onClick={() => setShowMetadata(!showMetadata)}>
            Metadata {showMetadata ? '▼' : '►'}
          </h3>
          {showMetadata && (
            <div className="table-layout">
              <label>
                Deal Date:
                <input type="date" name="deal_date" value={formData.deal_date} onChange={handleChange} required />
              </label>
              <label>
                Deal Number:
                <input type="number" name="deal_number" value={formData.deal_number} onChange={handleChange} readOnly />
              </label>
              <label>
                First Name:
                <input type="text" name="customer_name_first" value={formData.customer_name_first} onChange={handleChange} />
              </label>
              <label>
                Last Name:
                <input type="text" name="customer_name_last" value={formData.customer_name_last} onChange={handleChange} />
              </label>
              <label>
                VIN Number:
                <input type="text" name="vin_number" value={formData.vin_number} onChange={handleChange} />
              </label>
              <label>
                Stock Number:
                <input type="text" name="stock_number" value={formData.stock_number} onChange={handleChange} />
              </label>
              <label>
                Sale Type:
                <select name="sale_type" value={formData.sale_type} onChange={handleChange}>
                  <option value=""></option>
                  <option value="New">New</option>
                  <option value="Used">Used</option>
                </select>
              </label>
              <label>
                RDR Date:
                <input type="date" name="rdr_date" value={formData.rdr_date} onChange={handleChange} />
              </label>
            </div>
          )}
        </section>

        {/* Front Sale Section */}
        <section className="form-section">
          <h3 className="section-header" onClick={() => setShowFrontSale(!showFrontSale)}>
            Front Sale {showFrontSale ? '▼' : '►'}
          </h3>
          {showFrontSale && (
            <div className="table-layout">
              <label>
                Incentive:
                <input type="number" name="incentive" value={formData.incentive} onChange={handleChange} />
              </label>
              <label>
                Front Gross:
                <input type="number" name="front_gross" value={formData.front_gross} onChange={handleChange} />
              </label>
              <label>
                Retail Price:
                <input type="number" name="retail_price" value={formData.retail_price} onChange={handleChange} />
              </label>
              <label>
                Test Drive:
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    name="test_drive"
                    checked={formData.test_drive}
                    onChange={handleChange}
                  />
                </div>
              </label>
              <label>
                Test Drive Count:
                <input type="number" name="test_drive_count" value={formData.test_drive_count} onChange={handleChange} />
              </label>
              <label>
                Make:
                <input type="text" name="make" value={formData.make} onChange={handleChange} />
              </label>
              <label>
                Model:
                <input type="text" name="model" value={formData.model} onChange={handleChange} />
              </label>
              <label>
                Sold Model Year:
                <input type="number" name="sold_model_year" value={formData.sold_model_year} onChange={handleChange} />
              </label>
              <label>
                Trade Make:
                <input type="text" name="trade_make" value={formData.trade_make} onChange={handleChange} />
              </label>
              {/* Trade Details - Conditionally Rendered */}
              {formData.trade_make && (
                <>
                  <label>
                    Trade Model:
                    <input type="text" name="trade_model" value={formData.trade_model} onChange={handleChange} />
                  </label>
                  <label>
                    Trade Model Year:
                    <input type="number" name="trade_model_year" value={formData.trade_model_year} onChange={handleChange} />
                  </label>
                  <label>
                    Trade ACV:
                    <input type="number" name="trade_acv" value={formData.trade_acv} onChange={handleChange} />
                  </label>
                  <label>
                    Trade Allowance:
                    <input type="number" name="trade_allowance" value={formData.trade_allowance} onChange={handleChange} />
                  </label>
                </>
              )}

              {/* Salesperson 1 */}
              <label>
                Salesperson 1:
                <select name="salesperson_1" value={formData.salesperson_1} onChange={handleChange}>
                  <option value="">Select Salesperson</option>
                  {salespeople.map((person, index) => (
                    <option key={index} value={`${person.employee_name_first} ${person.employee_name_last}`}>
                      {`${person.employee_name_first} ${person.employee_name_last}`}
                    </option>
                  ))}
                </select>
              </label>

              {/* Salesperson 2 */}
              <label>
                Salesperson 2:
                <select name="salesperson_2" value={formData.salesperson_2} onChange={handleChange}>
                  <option value="">Select Salesperson</option>
                  {salespeople.map((person, index) => (
                    <option key={index} value={`${person.employee_name_first} ${person.employee_name_last}`}>
                      {`${person.employee_name_first} ${person.employee_name_last}`}
                    </option>
                  ))}
                </select>
              </label>

              {/* Sales Manager */}
              <label>
                Sales Manager:
                <select name="sales_manager" value={formData.sales_manager} onChange={handleChange}>
                  <option value="">Select Sales Manager</option>
                  {salesManagers.map((person, index) => (
                    <option key={index} value={`${person.employee_name_first} ${person.employee_name_last}`}>
                      {`${person.employee_name_first} ${person.employee_name_last}`}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}
        </section>

        {/* Back Sale Section */}
        <section className="form-section">
          <h3 className="section-header" onClick={() => setShowBackSale(!showBackSale)}>
            Back Sale {showBackSale ? '▼' : '►'}
          </h3>
          {showBackSale && (
            <div className="table-layout">
              {/* FI Producer */}
              <label>
                FI Producer:
                <select name="fi_producer" value={formData.fi_producer} onChange={handleChange}>
                  <option value="">Select FI Producer</option>
                  {fiProducers.map((person, index) => (
                    <option key={index} value={`${person.employee_name_first} ${person.employee_name_last}`}>
                      {`${person.employee_name_first} ${person.employee_name_last}`}
                    </option>
                  ))}
                </select>
              </label>

              {/* Payment Type */}
              <label>
                Payment Type:
                <select name="payment_type" value={formData.payment_type} onChange={handleChange}>
                  <option value="">Select Payment Type</option>
                  <option value="Cash">Cash</option>
                  <option value="Finance">Finance</option>
                  <option value="Lease">Lease</option>
                </select>
              </label>

              {/* Conditional Fields based on Payment Type */}
              {formData.payment_type !== 'Cash' && (
                <>
                  <label>
                    Down Payment:
                    <input type="number" name="down_payment" value={formData.down_payment} onChange={handleChange} />
                  </label>
                  <label>
                    Lender:
                    <input type="text" name="lender" value={formData.lender} onChange={handleChange} />
                  </label>
                  <label>
                    Term In:
                    <input type="number" name="term_in" value={formData.term_in} onChange={handleChange} />
                  </label>
                  <label>
                    Term Out:
                    <input type="number" name="term_out" value={formData.term_out} onChange={handleChange} />
                  </label>
                  
                  {/* Rate In - Percentage Input */}
                  <label className="percentage-label">
                    Rate In:
                    <div className="percentage-input-container">
                      <input
                        type="text"
                        name="rate_in"
                        value={formData.rate_in}
                        onChange={handleChange}
                        placeholder="e.g., 12.5%"
                      />
                      <span className="percentage-symbol">%</span>
                    </div>
                  </label>

                  {/* Rate Out - Percentage Input */}
                  <label className="percentage-label">
                    Rate Out:
                    <div className="percentage-input-container">
                      <input
                        type="text"
                        name="rate_out"
                        value={formData.rate_out}
                        onChange={handleChange}
                        placeholder="e.g., 8.75%"
                      />
                      <span className="percentage-symbol">%</span>
                    </div>
                  </label>

                  <label>
                    Pay In:
                    <input type="number" name="pay_in" value={formData.pay_in} onChange={handleChange} />
                  </label>
                  <label>
                    Pay Out:
                    <input type="number" name="pay_out" value={formData.pay_out} onChange={handleChange} />
                  </label>
                  <label>
                    Reserve:
                    <input type="number" name="reserve" value={formData.reserve} onChange={handleChange} />
                  </label>
                </>
              )}

              {/* Title and Payoff - Conditionally Rendered */}
              {formData.trade_make && (
                <>
                  <label>
                    Title:
                    <select name="title" value={formData.title} onChange={handleChange}>
                      <option value="">Select Title</option>
                      <option value="Clean">Clean</option>
                      <option value="Salvage">Salvage</option>
                    </select>
                  </label>
                  <label>
                    Payoff:
                    <input type="number" name="payoff" value={formData.payoff} onChange={handleChange} />
                  </label>
                </>
              )}

              {/* Products */}
              <label>
                Product 1:
                <input type="number" name="product_1" value={formData.product_1} onChange={handleChange} />
              </label>
              <label>
                Product 2:
                <input type="number" name="product_2" value={formData.product_2} onChange={handleChange} />
              </label>
              <label>
                Product 3:
                <input type="number" name="product_3" value={formData.product_3} onChange={handleChange} />
              </label>
              <label>
                Product 4:
                <input type="number" name="product_4" value={formData.product_4} onChange={handleChange} />
              </label>
              <label>
                Product 5:
                <input type="number" name="product_5" value={formData.product_5} onChange={handleChange} />
              </label>

              {/* Reserve is already included above based on payment type */}
              {formData.payment_type !== 'Cash' && (
                <label>
                  Reserve:
                  <input type="number" name="reserve" value={formData.reserve} onChange={handleChange} />
                </label>
              )}

              {/* Back Gross */}
              <label>
                Back Gross:
                <input type="number" name="back_gross" value={formData.back_gross} readOnly />
              </label>
            </div>
          )}
        </section>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default SalesForm;
