import React, { useState, useEffect, useCallback } from "react";
import Select from 'react-select';
import "./CustomerLogForm.css";

const CustomerLogForm = ({ user }) => {
  // Define the initial form data
  const initialFormData = {
    client_advisor: '',
    guest_name_first: '',
    guest_name_last: '',
    appt_set_by: '',
    client_info: '',
    in_system: false,
    lead_source: '',
    intro_type: '',
    stock_number: '',
    trade_vin: '',
    to_1: '',
    to_2: '',
    to_3: '',
    to_4: '',
    demo: false,
    write_up: false,
    comments: '',
    submitted: '',
    submitted_by: ''
  };

  // Initialize all hooks at the top level
  const [formData, setFormData] = useState(initialFormData);
  const [salespersons, setSalespersons] = useState([]);
  const [apptSetByOptions, setApptSetByOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchId, setSearchId] = useState('');
  const [showFetchedData, setShowFetchedData] = useState(false);
  const [stockNumbers, setStockNumbers] = useState([]);

  // Map stockNumbers to options
  const stockNumberOptions = stockNumbers.map(stock => ({
    value: stock,
    label: stock
  }));

  // Lead Source and Intro Type options
  const leadSourceOptions = ["cars.com", "gubagoo", "car gurus", "hendrickcars.com", "referral"];
  const introTypeOptions = ["campaign", "internet", "phone", "showroom"];

  // Fetch personnel data from the server
  const fetchPersonnelData = useCallback(async () => {
    if (!user || !user.token) {
      console.error('User or token not available');
      return;
    }

    const token = user.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/personnel`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();

        const salespersons = data.filter(person => person.role === 'salesperson')
          .map(person => `${person.employee_name_first} ${person.employee_name_last}`);
        setSalespersons(salespersons);

        const apptSetByOptions = data.filter(person => ['salesperson', 'fi producer', 'sales manager'].includes(person.role))
          .map(person => `${person.employee_name_first} ${person.employee_name_last}`);
        setApptSetByOptions(apptSetByOptions);

        const toOptions = data.filter(person => ['sales manager', 'fi producer'].includes(person.role))
          .map(person => `${person.employee_name_first} ${person.employee_name_last}`);
        setToOptions(toOptions);
      } else {
        console.error("Failed to fetch personnel data");
      }
    } catch (error) {
      console.error("Error fetching personnel data:", error);
    }
  }, [user]);

  // Fetch available stock numbers from the server
  const fetchStockNumbers = useCallback(async () => {
    if (!user || !user.token) {
      console.error('User or token not available');
      return;
    }

    const token = user.token;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/available-stock-numbers`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const availableStockNumbers = data.map(item => item.stock_number);
        setStockNumbers(availableStockNumbers);
      } else {
        console.error("Failed to fetch stock numbers");
      }
    } catch (error) {
      console.error("Error fetching stock numbers:", error);
    }
  }, [user]);

  // Fetch personnel data and stock numbers on component load
  useEffect(() => {
    const fetchData = async () => {
      await fetchPersonnelData();
      await fetchStockNumbers();
    };

    fetchData();
  }, [user, fetchPersonnelData, fetchStockNumbers]);

  const handleSearch = async () => {
    if (!user || !user.token) {
      console.error('User or token not available');
      return;
    }

    const token = user.token;

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/fetch-customer-log-by-id?guest_name_last=${encodeURIComponent(searchId)}`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          const latestRecord = data[0];
          console.log('Fetched Data:', latestRecord); // For debugging
          console.log('Stock Number in Fetched Data:', latestRecord.stock_number); // For debugging

          setFetchedData(latestRecord);
          setShowFetchedData(true);

          // Optionally pre-fill the form with the fetched data
          setFormData({
            ...formData,
            ...latestRecord,
            submitted: '', // Clear the 'submitted' field
            submitted_by: '', // Clear the 'submitted_by' field
          });
        } else {
          console.log("No records found for the given last name.");
          setFetchedData(null);
          setShowFetchedData(false);
        }
      } else {
        console.error("Failed to fetch data by last name");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target || {};
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user || !user.token || !user.firstName || !user.lastName) {
      console.error('User data not available');
      return;
    }

    const token = user.token;
    const currentTimestamp = new Date().toISOString();
    const submittedBy = `${user.firstName} ${user.lastName}`;
    console.log('Submitted By:', submittedBy);

    const updatedFormData = {
      ...formData,
      submitted: currentTimestamp,
      submitted_by: submittedBy
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/submit-customer-log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ formData: updatedFormData }),
      });

      if (response.ok) {
        console.log("Data inserted successfully");
        // Reset form data
        setFormData(initialFormData);
      } else {
        console.error("Failed to insert data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to clear the form and search inputs
  const handleClearForm = () => {
    const confirmClear = window.confirm("Are you sure you want to clear the form and search?");
    if (confirmClear) {
      setFormData(initialFormData);
      setSearchId('');
      setFetchedData(null);
      setShowFetchedData(false);
    }
  };

  // Extract and format the last seen date
  let lastSeenDisplay = 'Date not available';
  if (fetchedData && fetchedData.submitted) {
    const lastSeenDate = new Date(fetchedData.submitted);
    if (!isNaN(lastSeenDate)) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      lastSeenDisplay = lastSeenDate.toLocaleDateString(undefined, options);
    } else {
      console.error('Invalid Date:', fetchedData.submitted);
    }
  }

  // Conditional rendering based on user data
  if (!user || !user.firstName || !user.lastName) {
    return <div>Loading...</div>;
  }

  return (
    <div className="customer-form-container">
      <button className="centered-button" onClick={() => setShowSearch(!showSearch)}>
        {showSearch ? "Hide Search" : "Show Search"}
      </button>

      {showSearch && (
        <div className="search-section centered">
          <h3>Search Customer Log by Last Name</h3>
          <input
  type="text"
  placeholder="Enter Last Name"
  value={searchId}
  onChange={(e) => setSearchId(e.target.value)}
  className="search-input"
  aria-label="Enter Last Name"
/>
          <div className="search-buttons">
            <button className="centered-button" onClick={handleSearch}>Search</button>
            <button type="button" className="centered-button" onClick={handleClearForm}>Clear Form</button>
          </div>
        </div>
      )}

      {showFetchedData && fetchedData && (
        <div className="fetched-data">
          <h3>Latest Record for {fetchedData.guest_name_first} {fetchedData.guest_name_last}</h3>
          {lastSeenDisplay && (
            <p><strong>Last seen:</strong> {lastSeenDisplay}</p>
          )}
          <p><strong>Client Advisor:</strong> {fetchedData.client_advisor}</p>
          <p><strong>Appointment Set By:</strong> {fetchedData.appt_set_by}</p>
          {/* Display other relevant fields as needed */}
        </div>
      )}

      <form onSubmit={handleSubmit} className="customer-form">
        {/* Form fields container */}
        <div className="form-fields">
          <div className="form-group">
            <label>Client Advisor:</label>
            <select name="client_advisor" value={formData.client_advisor} onChange={handleChange}>
              <option value="">Select Client Advisor</option>
              {salespersons.map((advisor, index) => (
                <option key={index} value={advisor}>{advisor}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Appointment Set By:</label>
            <select name="appt_set_by" value={formData.appt_set_by} onChange={handleChange}>
              <option value="">Select</option>
              {apptSetByOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Client First Name:</label>
            <input type="text" name="guest_name_first" value={formData.guest_name_first} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label>Client Last Name:</label>
            <input type="text" name="guest_name_last" value={formData.guest_name_last} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label>Client Info:</label>
            <input type="text" name="client_info" value={formData.client_info} onChange={handleChange} />
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input type="checkbox" name="in_system" checked={formData.in_system} onChange={handleChange} />
              In System
            </label>
          </div>

          <div className="form-group">
            <label>Lead Source:</label>
            <select name="lead_source" value={formData.lead_source} onChange={handleChange}>
              <option value="">Select Lead Source</option>
              {leadSourceOptions.map((source, index) => (
                <option key={index} value={source}>{source}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Intro Type:</label>
            <select name="intro_type" value={formData.intro_type} onChange={handleChange}>
              <option value="">Select Intro Type</option>
              {introTypeOptions.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Stock Number:</label>
            <Select
  name="stock_number"
  value={stockNumberOptions.find(option => option.value === formData.stock_number) || null}
  onChange={(selectedOption) => {
    handleChange({
      target: {
        name: 'stock_number',
        value: selectedOption ? selectedOption.value : ''
      }
    });
  }}
  options={stockNumberOptions}
  isClearable
  placeholder="Enter or select stock number"
  className="custom-react-select" // Assign a custom class
  classNamePrefix="react-select" // Prefix for internal classes
/>
          </div>

          <div className="form-group">
            <label>Trade VIN:</label>
            <input type="text" name="trade_vin" value={formData.trade_vin} onChange={handleChange} />
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input type="checkbox" name="demo" checked={formData.demo} onChange={handleChange} />
              Demo
            </label>
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input type="checkbox" name="write_up" checked={formData.write_up} onChange={handleChange} />
              Write Up
            </label>
          </div>

          <div className="form-group">
            <label>TO 1:</label>
            <select name="to_1" value={formData.to_1} onChange={handleChange}>
              <option value="">Select</option>
              {toOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>TO 2:</label>
            <select name="to_2" value={formData.to_2} onChange={handleChange}>
              <option value="">Select</option>
              {toOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>TO 3:</label>
            <select name="to_3" value={formData.to_3} onChange={handleChange}>
              <option value="">Select</option>
              {toOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>TO 4:</label>
            <select name="to_4" value={formData.to_4} onChange={handleChange}>
              <option value="">Select</option>
              {toOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="form-group full-width">
            <label>Comments:</label>
            <textarea name="comments" value={formData.comments} onChange={handleChange}></textarea>
          </div>
        </div>

        {/* Submit button container */}
        <div className="form-actions">
          <button type="submit" className="centered-button">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default CustomerLogForm;
