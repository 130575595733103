import React, { useEffect, useState, useCallback } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Line, Doughnut, Bubble } from "react-chartjs-2";
import { Range } from "react-range";
import "./InventoryDashboard.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);

const InventoryDashboard = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dateRange, setDateRange] = useState([0, 100]);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [saleType, setSaleType] = useState("All");
  const [editingStartDate, setEditingStartDate] = useState(false);
  const [editingEndDate, setEditingEndDate] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/inventory-data`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setInventoryData(data);
        if (data.length > 0) {
          const dates = data.map((item) => new Date(item.stock_in_date.value));
          const minStockDate = new Date(Math.min(...dates));
          const maxStockDate = new Date(Math.max(...dates));
          setMinDate(minStockDate);
          setMaxDate(maxStockDate);
          setDateRange([0, 100]);
          setFilteredData(data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const averageSalePrice =
    filteredData.length > 0
      ? filteredData.reduce((sum, item) => sum + item.sale_price, 0) /
        filteredData.length
      : 0;

  const totalUnits = filteredData.length;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

const getDateFromNormalized = useCallback(
    (normalizedValue) => {
      const timeSpan = maxDate - minDate;
      return new Date(minDate.getTime() + timeSpan * (normalizedValue / 100));
    },
    [minDate, maxDate] // Dependencies
  ); 

  const filterDataByDateRangeAndSaleType = useCallback(() => {
  const filtered = inventoryData.filter((item) => {
    const date = new Date(item.stock_in_date.value);
    const inDateRange =
      date >= getDateFromNormalized(dateRange[0]) &&
      date <= getDateFromNormalized(dateRange[1]);
    const matchesSaleType = saleType === "All" || item.sale_type === saleType;
    return inDateRange && matchesSaleType;
  });
  setFilteredData(filtered);
}, [dateRange, saleType, inventoryData, getDateFromNormalized]); // Include getDateFromNormalized
  
useEffect(() => {
  filterDataByDateRangeAndSaleType();
}, [filterDataByDateRangeAndSaleType]); // Clean dependency

  const handleSaleTypeChange = (e) => {
    setSaleType(e.target.value);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = new Date(e.target.value);
    if (newStartDate <= maxDate) {
      const newRange = [
        ((newStartDate - minDate) / (maxDate - minDate)) * 100,
        dateRange[1],
      ];
      setDateRange(newRange);
    }
    setEditingStartDate(false);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = new Date(e.target.value);
    if (newEndDate >= minDate) {
      const newRange = [
        dateRange[0],
        ((newEndDate - minDate) / (maxDate - minDate)) * 100,
      ];
      setDateRange(newRange);
    }
    setEditingEndDate(false);
  };

  // Sort filteredData by stock_in_date
  const sortedData = filteredData.sort(
    (a, b) => new Date(a.stock_in_date.value) - new Date(b.stock_in_date.value)
  );

  const lineChartData = {
    labels: sortedData.map((item) => formatDate(item.stock_in_date.value)),
    datasets: [
      {
        label: "Sale Price",
        data: sortedData.map((item) => item.sale_price),
        fill: false,
        borderColor: "rgba(0, 127, 255, 1)",
        tension: 0.4, // Smooth line
        cubicInterpolationMode: 'monotone', // Smooth curve
      },
    ],
  };

  const lineChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Sale Price: $${context.raw.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Sale Price ($)",
        },
      },
    },
  };

  const makeCounts = sortedData.reduce((acc, item) => {
    acc[item.make] = (acc[item.make] || 0) + 1;
    return acc;
  }, {});

  const doughnutChartData = {
    labels: Object.keys(makeCounts),
    datasets: [
      {
        label: "Count of Inventory by Make",
        data: Object.values(makeCounts),
        backgroundColor: [
          "#8ec6ff",
          "#7fb9ff",
          "#70acff",
          "#60a0ff",
          "#5193ff",
          "#3d8eff",
          "#2889ff",
          "#1484ff",
          "#007fff",
          "#006cd9",
          "#0059b2",
          "#00468c",
          "#003366",
          "#30567d",
          "#607a93",
          "#909daa",
          "#c0c0c0",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const doughnutChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
    },
  };

  const bubbleChartData = {
    datasets: sortedData.map((item) => {
      const profit = item.internet_price - item.inventory_cost;
      const stockDate = new Date(item.stock_in_date.value);
      const ageInDays = (new Date() - stockDate) / (1000 * 60 * 60 * 24); // Difference in days
      return {
        label: item.vin_number,
        data: [{ x: ageInDays, y: profit, r: 5 }],
        backgroundColor: "rgba(0, 127, 255, 0.5)",
        hoverBackgroundColor: "rgba(0, 127, 255, 1)",
      };
    }),
  };

  const bubbleChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { x, y } = context.raw;
            return `Profit: $${x.toLocaleString()}, Age: ${y.toFixed(0)} days`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Age of Inventory (days)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Profit ($)",
        },
      },
    },
  };

  return (
    <div className="inventory-dashboard">
      <h2 className="dashboard-header">Inventory Dashboard</h2>
      <div className="filters">
        <div className="sale-type-filter">
          <label>
            <input
              type="radio"
              value="All"
              checked={saleType === "All"}
              onChange={handleSaleTypeChange}
            />
            All
          </label>
          <label>
            <input
              type="radio"
              value="New"
              checked={saleType === "New"}
              onChange={handleSaleTypeChange}
            />
            New
          </label>
          <label>
            <input
              type="radio"
              value="Used"
              checked={saleType === "Used"}
              onChange={handleSaleTypeChange}
            />
            Used
          </label>
        </div>
        <div className="slider-container">
          <h3 className="slider-label">Select Date Range</h3>
          <Range
            step={1}
            min={0}
            max={100}
            values={dateRange}
            onChange={setDateRange}
            renderTrack={({ props, children }) => (
              <div {...props} className="range-track">
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div {...props} className="range-thumb" />
            )}
          />
          <div className="date-range-display">
            {editingStartDate ? (
              <input
                type="date"
                value={getDateFromNormalized(dateRange[0])
                  .toISOString()
                  .substr(0, 10)}
                onBlur={handleStartDateChange}
                autoFocus
              />
            ) : (
              <span onClick={() => setEditingStartDate(true)}>
                {formatDate(getDateFromNormalized(dateRange[0]))}
              </span>
            )}
            {" - "}
            {editingEndDate ? (
              <input
                type="date"
                value={getDateFromNormalized(dateRange[1])
                  .toISOString()
                  .substr(0, 10)}
                onBlur={handleEndDateChange}
                autoFocus
              />
            ) : (
              <span onClick={() => setEditingEndDate(true)}>
                {formatDate(getDateFromNormalized(dateRange[1]))}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="stats">
        <div className="average-sale-price">
          <h3> Average {saleType} Sale Price{" "} </h3>
            <div className="average-price-value">
              ${averageSalePrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
        </div>
        <div className="total-units">
          <h3> {saleType} Units in Inventory{" "} </h3>
            <span className="total-units-value">{totalUnits}</span>
        </div>
      </div>
      <div className="chart-grid">
        <div className="chart-item">
          <h3>Inventory Count by Date</h3>
          <Line data={lineChartData} options={lineChartOptions} />
        </div>
        <div className="chart-item">
          <h3>Inventory Count by Make</h3>
          <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
        </div>
        <div className="chart-item">
          <h3>Profit vs Age of Inventory</h3>
          <Bubble data={bubbleChartData} options={bubbleChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default InventoryDashboard;
